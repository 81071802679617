<template>
  <v-container fluid class="down-top-padding pt-0">
    <v-row>
      <clear-due-payment
        v-if="clearDueDialog"
        :selectedUser="selectedMember"
        :dialogStatus="clearDueDialog"
        @close="clearDueDialog = false"
        @refresh="initialize"
      ></clear-due-payment>
      <v-col cols="12">
        <div>
          <v-data-table
            :loading="loading"
            item-key="id"
            class="elevation-0 pb-4 pt-4"
            :headers="headers"
            :items="duePayments"
            :expanded.sync="expanded"
            :show-expand="false"
            :page.sync="page"
            :custom-sort="customSort"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:top class="px-3">
              <v-toolbar flat class="mb-4 px-3">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('member.search')"
                  @input="searchDuePayments(1)"
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers }">
              <td :colspan="headers.length"></td>
            </template>
            <template v-slot:item.user_subscription.amount="{ item }">
              <span>{{
                item.user_subscription && "$" + item.user_subscription.amount
              }}</span>
            </template>
            <template v-slot:item.user_subscription.subscription_starts_at="{ item }">
              <span>
                {{ item.user_subscription?.subscription_ends_at && item.user_subscription.subscription_starts_at + ' - ' + item.user_subscription.subscription_ends_at }}
              </span>
            </template>
            <template v-slot:item.current_status="{ item }">
              <span
                :class="
                  item.current_status === 'unpaid'
                    ? 'unpaid-payment'
                    : 'paid-payment'
                "
                >{{ item.current_status }}</span
              >
              <h6 v-if="item.current_status === 'unpaid'" style="color: red;">
                {{ item?.user_payment?.failed_reason && item?.user_payment?.failed_reason }}
              </h6>
              <h6 v-if="item.current_status !== 'unpaid'">
                {{
                  (item.user_subscription
                    ? item.user_subscription.subscription_ends_at
                    : "") | datefilter
                }}
              </h6>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize(1)"> Reset </v-btn>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-if="duePayments.length > 0"
              v-model="meta.current_page"
              :length="meta.last_page"
              @input="initialize"
            ></v-pagination>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { showSnackbar } from "../eventbus/action.js";
import ClearDuePayment from "../components/financial/ClearDuePayment";
import moment from "moment";
export default {
  name: "DuePayment",
  components: {
    ClearDuePayment,
  },
  data() {
    return {
      payload: {},
      clearDueDialog: false,
      expanded: [],
      page: 1,
      pageCount: 2,
      itemsPerPage: 50,
      search: "",
      headers: [
        {
          text: this.$t("member.id"),
          align: "start",
          value: "id",
          width: "10%",
          sortable: false,
        },
        {
          text: this.$t("member.companyName"),
          value: "name",
          width: "15%",
          sortable: false,
        },
        {
          text: this.$t("member.monthlyCost"),
          value: "user_subscription.amount",
          width: "10%",
        },
        {
          text: this.$t("member.billingCycle"),
          value: "user_subscription.subscription_starts_at",
          width: "20%",
        },
        {
          text: this.$t("member.paymentMethod"),
          value: "payment_method.name",
          width: "15%",
        },
        {
          text: this.$t("member.status"),
          value: "current_status",
          width: "11%",
          sortable: false,
        },
      ],
      selectedMember: {
        user_detail: {},
        user_subscription: {},
      },
      previousIndex: [],
      previousOrder: [],
      loading: false,
      datePicker: false,
      date: moment().format("YYYY-MM"),
    };
  },
  computed: {
    ...mapGetters({
      duePayments: "duePayment/getDuePayments",
      meta: "duePayment/getDuePaymentsMeta",
      user: "user/getUser",
    }),
  },
  mounted() {
    this.initialize(1);
  },

  methods: {
    ...mapActions({
      getDuePayments: "duePayment/getDuePayments",
    }),

    async initialize(page) {
      this.page = page;
      this.loading = true;
      try {
        await this.getDuePayments({
          search: this.search,
          page: this.page,
          per_page: this.itemsPerPage,
          current_status: 'unpaid',
          // date: this.date,
          ...this.payload,
        });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showSnackbar(errorMessage);
      } finally {
        this.loading = false;
      }
    },
    searchDuePayments: _.debounce(function (page) {
      this.initialize(page);
    }, 500),

    viewTransaction(item) {
      this.selectedMember = item;
      this.$router.push({
        name: "TransactionHistory",
        params: { id: this.selectedMember.id },
      });
    },

    customSort(items, index, isDescending) {
      if (
        _.isEqual(this.previousIndex, index) &&
        _.isEqual(this.previousOrder, isDescending)
      ) {
        return items;
      }
      this.previousIndex = index;
      this.previousOrder = isDescending;
      this.payload = {};
      if (isDescending.length && isDescending[0]) {
        this.payload = {
          ...this.payload,
          sortOrder: "desc",
          sortBy:
            index[0] === "payment_method.name"
              ? "payment_method_name"
              : "id",
        };
      } else if (isDescending.length && !isDescending[0]) {
        this.payload = {
          ...this.payload,
          sortOrder: "asc",
          sortBy:
            index[0] === "payment_method.name"
              ? "payment_method_name"
              : "id",
        };
      }
      this.initialize(this.page);
      return this.duePayments;
    },

    selectItem(item) {
      this.clearDueDialog = true;
      this.selectedMember = _.cloneDeep(item);
    },

    getServices(data) {
      return data.map((val) => val.name).join(", ");
    },
  },
};
</script>
<style>
.v-date-picker-table .v-btn.v-btn--active {
  color: #fff;
  font-size: 17px;
}
</style>